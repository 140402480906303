import * as React from 'react'
import * as clientesStyles from "./clientes.module.scss"
import Titulo from './smalls/titulo'
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"


const Clientes = () => { 

    /*const ClientesList = [
        {"Nombre" : "Pedre","logo":"../images/clientes/Pedre.png"},
        {"Nombre" : "SkyTown","logo":"../images/clientes/SkyTown.png" },
        {"Nombre" : "Maranta Bosques" ,"logo":"../images/clientes/Maranta.png"},
        {"Nombre" : "Conjunto Nuevo León","logo":"../images/clientes/ConjuntoNuevoLeon.png"},
        {"Nombre" : "Colliers","logo":"../images/clientes/Colliers.png" },
        {"Nombre" : "Alpinia","logo":"../images/clientes/Alpinia.png" },
        {"Nombre" : "Agára","logo":"../images/clientes/Agara.png" },
        {"Nombre" : "Ximal","logo":"../images/clientes/Ximal.png"},
        {"Nombre" : "Barrio Roma","logo":"../images/clientes/Barrio Roma.png" },
        {"Nombre" : "Binóme","logo":"../images/clientes/Binome.png" },
        {"Nombre" : "Puerta Jardín","logo":"../images/clientes/PuertaJardin.png" },
        {"Nombre" : "2020 Cocktail Collection","logo":"../images/clientes/2020Cocktail.png" },
        {"Nombre" : "dikat","logo":"../images/clientes/ikat.png" },
        {"Nombre" : "Valtime","logo":"../images/clientes/Valtime.png" },
        {"Nombre" : "Puerta de Hierro","logo":"../images/clientes/PuertaDeHierro.png" },
        {"Nombre" : "Espacio Condesa","logo":"../images/clientes/EspacioCondesa.png" },
        {"Nombre" : "Aida","logo":"../images/clientes/Aida.png" },
        {"Nombre" : "Bora","logo":"../images/clientes/Bora.png" },
        {"Nombre" : "Factoma","logo":"../images/clientes/Factoma.png" },
        {"Nombre" : "Bitare","logo":"../images/clientes/Bitare.png" },
    ]

    const clientesListaHTML = [];

    ClientesList.forEach( (cliente) => {
        console.log(cliente);
        clientesListaHTML.push(
            <StaticImage src={cliente.logo} alt={cliente.Nombre} />
        )
    } )*/

    const threshold = 0.1;

    const [ref, inView] = useInView({ threshold })

    return (
        <section  id="clientes" 
          ref={ref} className={clientesStyles.section}>
            <div className={clientesStyles.container}>
                <Titulo>Clientes</Titulo>
                <div className={clientesStyles.imagenes}>
                    <StaticImage  style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Pedre.png"  alt="Pedre" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/SkyTown.png" alt="SkyTown" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Maranta.png" alt="Maranta Bosques" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/ConjuntoNuevoLeon.png" alt="Conjunto Nuevo León" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Colliers.png" alt="Colliers" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Alpinia.png" alt="Alpinia" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Agara.png" alt="Agára" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Ximal.png" alt="Ximal" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/BarrioRoma.png" alt="Barrio Roma" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Binome.png" alt="Binóme" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/PuertaJardin.png" alt="Puerta Jardín" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/2020Cocktail.png" alt="2020 Cocktail Collection" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Dikat.png" alt="dikat" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Valtime.png" alt="Valtime" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/PuertaDeHierro.png" alt="Puerta de Hierro" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/EspacioCondesa.png" alt="Espacio Condesa" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Aida.png" alt="Aida" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Bora.png" alt="Bora" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Factoma.png" alt="Factoma" />
                    <StaticImage style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 10}px)`,
          }} className={clientesStyles.imagen} src="../images/clientes/Bitare.png" alt="Bitare" />
                </div>
            </div>
        </section>
    )
}

export default Clientes;