import * as React from 'react'
import * as contactoStyles from "./contacto.module.scss"
import Titulo from './smalls/titulo'

function Contacto() {

    const [formulario, setformulario] = React.useState(0);
    const [mensajeError, setmensajeError] = React.useState(0);
    const isBrowser = () => typeof window !== "undefined";
    const estado = {
        "data" : {
            "fields": [
            ],
            "context": {
                "pageUri": (isBrowser()?window.location.href:""),
                "pageName":  (isBrowser()?document.title:"")
            }
        },
        "campos" : {
            "email" : "",
            "firstname" : "",
            "message" : ""
        }
    };

    function handleChange(event){
        estado["campos"][event.target.name] = event.target.value;
        let campos = [];
        for(let campo in estado["campos"]){
            campos.push({
                "name" : campo,
                "value" : estado["campos"][campo]
            });
        }
        estado["data"]["fields"] = campos;
    }

    function handleSubmit(event){
        event.preventDefault();

        for(let campo in estado["campos"]){
            if(estado["campos"][campo] == ""){
                setmensajeError( "Se requieren todos los campos" )
                setformulario(-1);
                return;
            }
        }

            var url = "https://api.hsforms.com/submissions/v3/integration/submit/22082473/c20ed6f3-780e-4f99-a017-9a69badce9ab";
            fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                  'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(estado.data) // body data type must match "Content-Type" header
              }).then(response => {
                if(response.ok) {   
                    setformulario(1);
                  } else {
                    setmensajeError( "Tu mensaje no pudo ser enviado, ¿Podrías ponerte en contacto por otro medio?" )
                    setformulario(-1);
                  }
              })
              .catch(function(error) {
                setmensajeError( "Tu mensaje no pudo ser enviado, ¿Podrías ponerte en contacto por otro medio?" )
                setformulario(-1);
              });

    }
   
  return (
    <section id="contacto"  className={contactoStyles.section}>
        <div className={contactoStyles.container}>
            <Titulo color="#000">Contacto</Titulo>
            <div  className={contactoStyles.containerDiv}>
                <form  onSubmit={handleSubmit} className={contactoStyles.form} method="post" action="https://api.hsforms.com">
                    <label className={contactoStyles.labelContacto} for="firstname">
                        NOMBRE  
                        <input className={contactoStyles.imputContacto} type="text" name="firstname" id="firstname" onChange={handleChange} />
                    </label>
                    
                    <label className={contactoStyles.labelContacto} for="email">
                        MAIL
                        <input className={contactoStyles.imputContacto}  type="email" name="email" id="email" onChange={handleChange} />
                    </label>
                    <div className={contactoStyles.divBtn}>
                        <div>
                            <label className={contactoStyles.labelContacto} for="message">
                                MENSAJE    
                            </label>
                            <button className={contactoStyles.btnContacto} type="submit">ENVIAR</button>
                        </div>
                        <textarea className={contactoStyles.imputContacto} name="message" id="message" rows="2"  onChange={handleChange}/>
                    </div>    
                    <div className={[(formulario==-1 ? contactoStyles.errorActive : "") , contactoStyles.error].join(" ") }>
                                    {mensajeError}
                    </div>
                    <div className={[(formulario==1 ? contactoStyles.overlayActive : "") , contactoStyles.overlay].join(" ") }>
                        <div className={contactoStyles.formDone}>
                                    Mensaje Enviado, Nos pondremos en contacto a la brevedad
                        </div>
                    </div>
                </form>
            </div>

            <div className={contactoStyles.containerDiv}>
                <a className={contactoStyles.correo} href="mailto:MONVALDES@THESHAPEOF.MX"><span><b>MONVALDES@THESHAPEOF.MX</b></span></a>
                <a href="tel:5215519527015" className={contactoStyles.marginBottom}>+52 1 55 1952 70 15</a>
                <span className={contactoStyles.marginBottom}>LAGO ISEO 240 ANAHUAC</span>
                <span>11320 CDMX MX</span>
            </div>
        </div>
    </section>
)
}
export default Contacto