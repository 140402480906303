import React, {useEffect} from "react"
import * as bannerStyles from "./banner.module.scss"
import VideoElement from "./smalls/videoElement"

//Video banner
import videoBanner from "../video/poster_result2.jpg"
//Video Reel Desktop
import reelDesktop264 from "../video/reelDesktop264.mp4"
import reelDesktop265 from "../video/reelDesktop265.mp4"
import reelDesktopVP9 from "../video/reelDesktopVP9.webm"
//Video Reel Desktop Completo
import reelDesktopC264 from "../video/reelDesktopC264.mp4"
import reelDesktopC265 from "../video/reelDesktopC265.mp4"
import reelDesktopCVP9 from "../video/reelDesktopCVP9.webm"

//Video Reel Mobile
import reelMobile264 from "../video/reelMovilSm264.mp4"
import reelMobile265 from "../video/reelMovilSm265.mp4"
import reelMobileVP9 from "../video/reelMovilSmVP9.webm"
//Video Reel Mobile Completo
import reelMobileC264 from "../video/reelMovilC264.mp4"
import reelMobileC265 from "../video/reelMovilC265.mp4"
import reelMobileCVP9 from "../video/reelMovilCVP9.webm"

export default function Banner() {

  const [estado, setEstado] = React.useState(false);


    function changeState(e){
        //document.body.style.overflow = (!estado ? "hidden" : "auto");
        //console.log( window.innerWidth > window.innerHeight);
        //console.log([window.innerWidth, window.innerHeight])
        if( window.innerWidth > window.innerHeight ){
          //console.log("Carga Desktop");
          document.getElementById("videoCompletoVP9").src = document.getElementById("videoCompletoVP9").dataset.srcdesktop;
          document.getElementById("videoCompleto265").src = document.getElementById("videoCompleto265").dataset.srcdesktop;
          document.getElementById("videoCompleto264").src = document.getElementById("videoCompleto264").dataset.srcdesktop;
        }else{
          //console.log("Carga Movil");
          document.getElementById("videoCompletoVP9").src = document.getElementById("videoCompletoVP9").dataset.srcmobile;
          document.getElementById("videoCompleto265").src = document.getElementById("videoCompleto265").dataset.srcmobile;
          document.getElementById("videoCompleto264").src = document.getElementById("videoCompleto264").dataset.srcmobile;
        }
        document.getElementById("videoCompleto").load();
        setEstado(!estado);   
    }

    useEffect(() => {
  }, []);


      /*if( window.innerWidth > window.innerHeight ){
        console.log("Carga Desktop");
        document.getElementById("videoCortoVP9").src = document.getElementById("videoCortoVP9").dataset.srcdesktop;
        document.getElementById("videoCorto265").src = document.getElementById("videoCorto265").dataset.srcdesktop;
        document.getElementById("videoCorto264").src = document.getElementById("videoCorto264").dataset.srcdesktop;
      }else{
        console.log("Carga Movil");
        //document.getElementById("videoCortoVP9").src = document.getElementById("videoCortoVP9").dataset.srcmobile;
        //document.getElementById("videoCorto265").src = document.getElementById("videoCorto265").dataset.srcmobile;
        //document.getElementById("videoCorto264").src = document.getElementById("videoCorto264").dataset.srcmobile;
      }
      //document.getElementById("videoCorto").load();
      //document.getElementById("videoCorto").play();
      }, [])*/

  return (
    <section id="inicio" className={bannerStyles.banner}>
        <div
        className={bannerStyles.videoBackground}
      dangerouslySetInnerHTML={{
        __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
          className="${bannerStyles.videoBackgroundDesktop}"
        >
            <source src="${reelDesktopVP9}" type="video/webm" media="(min-width: 720px)" />
            <source src="${reelDesktop265}" type="video/mp4" media="(min-width: 720px)" />
            <source src="${reelDesktop264}" type="video/mp4" media="(min-width: 720px)" />
            
        </video>
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
        >
            <source src="${reelMobileVP9}" type="video/webm"  />
            <source src="${reelMobile265}" type="video/mp4"  />
            <source src="${reelMobile264}" type="video/mp4"  />
            
        </video>`
      }}
    />
        <button onClick={changeState} className={bannerStyles.btn} aria-label="Abrir Video">
            Ver Reel &gt;
        </button>
        <div className={[(estado ? bannerStyles.popUpActive : "") , bannerStyles.popUp].join(" ") }>
          <button onClick={changeState} className={bannerStyles.btnClose} aria-label="Cerrar Video">
              x
          </button>
          <video id="videoCompleto" controls className={bannerStyles.videoCompleto}>
            <source id="videoCompletoVP9" data-srcdesktop={reelDesktopCVP9} data-srcmobile={reelMobileCVP9} type="video/webm"/>
            <source id="videoCompleto265" data-srcdesktop={reelDesktopC265} data-srcmobile={reelMobileC265} type="video/mp4"/>
            <source id="videoCompleto264" data-srcdesktop={reelDesktopC264} data-srcmobile={reelMobileC264} type="video/mp4"/>
          </video>
        </div>
    </section>
  )
}