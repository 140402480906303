// extracted by mini-css-extract-plugin
export var btnContacto = "contacto-module--btnContacto--m81p5";
export var container = "contacto-module--container--+7Tww";
export var containerDiv = "contacto-module--containerDiv--TXFUW";
export var correo = "contacto-module--correo--Bzf-R";
export var divBtn = "contacto-module--divBtn--LBkYW";
export var error = "contacto-module--error--JHGla";
export var errorActive = "contacto-module--errorActive--qHm08";
export var form = "contacto-module--form--yIQA5";
export var imputContacto = "contacto-module--imputContacto--JB4B0";
export var labelContacto = "contacto-module--labelContacto--YD8rm";
export var marginBottom = "contacto-module--marginBottom--jyDn9";
export var overlay = "contacto-module--overlay--1MtqL";
export var overlayActive = "contacto-module--overlayActive--uECK1";
export var section = "contacto-module--section--UayhB";