import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Nosotros from "../components/nosotros"
import Clientes from "../components/clientes"
import Servicios from "../components/servicios"
import Casos from "../components/casos"
import Contacto from "../components/contacto"
import Vacantes from "../components/vacantes"

export default function Index() {
  return(
  <Layout title={"The Shape Of"}>
    <Seo title="The Shape Of" />
    <Banner/>
    <Nosotros/>
    <Clientes/>
    <Servicios/>
    <Casos/>
    <Contacto/>
    <Vacantes/>
    {/*   
    <Contacto/>
    <Vacantes/> */}
  </Layout>
)
}
