// extracted by mini-css-extract-plugin
export var background = "casos-module--background--Uj-2R";
export var caso = "casos-module--caso--Gd0Dp";
export var formCampo = "casos-module--formCampo--gMtoU";
export var formSubmit = "casos-module--formSubmit--wpLyp";
export var logo = "casos-module--logo---6jMS";
export var popup = "casos-module--popup--i8cl+";
export var popupActive = "casos-module--popupActive--MH+zW";
export var popupBackground = "casos-module--popupBackground--TbDHy";
export var popupTexto = "casos-module--popupTexto--shcnw";
export var proximamente = "casos-module--proximamente--1C3Cz";
export var section = "casos-module--section--dXiNn";
export var width18 = "casos-module--width18--IBEt0";
export var width28 = "casos-module--width28--z9vc-";
export var z2 = "casos-module--z2--siBOZ";