import * as React from 'react'
import { useInView } from "react-intersection-observer"
import * as tituloStyles from "./titulo.module.scss"

function Titulo({children,color="#FFF"}) {
    const threshold = 0.9;

    const [ref, inView] = useInView({ threshold })

    return (
        <div ref={ref}  className={tituloStyles.titulo}>
            <div style={{
            opacity: inView ? 1 : 0,
            transform: `translateX(${inView ? 0 : 100}px)`,
          }} className={tituloStyles.tituloTop}><div className={tituloStyles.linea} style={{backgroundColor: color}}></div><h3>The Shape of</h3></div>
            <h2 style={{
            opacity: inView ? 1 : 0,
            transform: `translateX(${inView ? 0 : 100}px)`,
          }} className={tituloStyles.tituloBottom}>{children}</h2>
        </div>
    )
}
export default Titulo