import * as React from 'react'
import * as vacantesStyles from "./vacantes.module.scss"
import Titulo from './smalls/titulo'

function Vacantes() {
  return (
    <section id="vacantes"  className={vacantesStyles.section}>
        <div className={vacantesStyles.container}>
            <Titulo color="#000">Vacantes</Titulo>
            <div className={vacantesStyles.colabora}>
                <span className={vacantesStyles.colorGris} >Colabora con nosotros, revisa nuestras vacantes <a className={vacantesStyles.colorGris} href='https://www.linkedin.com/company/the-shape-of-mx/jobs/'>aquí.</a></span>
            </div>
        </div>
    </section>
)
}
export default Vacantes