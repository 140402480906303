import React, {useEffect} from "react"
import * as serviciosStyles from "./servicios.module.scss"
import Titulo from './smalls/titulo'
import { useInView } from "react-intersection-observer"

const ServiciosList = [
    {"Nombre" : "Inbound Marketing","id":"01","Descripcion":"Diseñamos estrategias para atraer clientes a través de contenido útil, relevante y agregando valor en cada una de las etapas del Buyer’s Journey. " },
    {"Nombre" : "Conceptualización Creativa","id":"02","Descripcion":"Desarrollamos campañas de alto impacto que logran crear conexiones auténticas con nuestro target, gracias a un equipo creativo con larga experiencia. " },
    {"Nombre" : "S-Marketing" ,"id":"03","Descripcion":"De la mano con tu equipo comercial, damos seguimiento a los prospectos a través de la administración del CRM Hubspot. Detectamos oportunidades e implementamos mejoras de segmentación. "},
    {"Nombre" : "Compra de Medios","id":"04","Descripcion":"Realizamos un plan de medios basado en tasas de conversión medibles y comparables. No cobramos fee en la inversión. " },
    {"Nombre" : "Generadores de Contenido","id":"05","Descripcion":"Elaboramos contenido de valor, que además de conectar con una audiencia, construyen una relación de largo plazo a través de la educación y la información oportuna." },
    {"Nombre" : "Desarrollo Web","id":"06","Descripcion":"Desarrollamos sitios web, aplicaciones y e-mail marketing. Implementamos SEO y SEM. Administramos blogs y automatizamos llamadas." },
    {"Nombre" : "CRM","id":"07","Descripcion":"Administramos las bases de datos de tus clientes potenciales y optimizamos nuestras campañas en tiempo real gracias a la información comercial recopilada en nuestro CRM. " },
    {"Nombre" : "Producción Audiovisual","id":"08","Descripcion":"Realizamos levantamiento de imágenes de avance de obra, registro de interiores y exteriores. Producciones de contenido audiovisual en campo o en nuestro estudio de producción." },
    {"Nombre" : "Planeación Estratégica","id":"09","Descripcion":"Relacionamos información y conocimiento del consumidor a través de la investigación para encontrar oportunidades de negocio." },
    {"Nombre" : "Relaciones Públicas","id":"10" ,"Descripcion":"Construimos planes de relaciones públicas que buscan generar información de freepress con medios digitales, offline, tradicionales y de nicho."},
]

function Servicios() {

    const threshold = 0.1;

    const [ref, inView] = useInView({ threshold })

    const serviciosListaHTML = [];

    ServiciosList.forEach( (servicio) => {
        serviciosListaHTML.push(
            <li style={{
                opacity: inView ? 1 : 0,
                transform: `translateX(${inView ? 0 : 20}px)`,
              }}><input name="radioServicio" type="radio" id={"btnControl" + servicio.id}/><label class={serviciosStyles.btn} for={"btnControl" + servicio.id}>{servicio.Nombre}<div className={serviciosStyles.numero}>{servicio.id}</div><div className={serviciosStyles.descripcion}>{servicio.Descripcion}</div></label>
              </li>
        )
    } )

    function mueve(){

    }
    useEffect(() => {
    document.getElementById('servicios').addEventListener('mousemove', function(e) {
       
        let seccion = document.querySelector('#servicios');
        let circle = document.getElementById('ServiciosFollow');
        let main = document.querySelector("main");

        let top = main.scrollTop + e.clientY - seccion.offsetTop;
        let left = e.clientX;

        let porcentajeY = top * 180 / seccion.clientHeight;
        let porcentajeX = left * 180/ seccion.clientWidth;

        let color = "hsl(" + (porcentajeY + porcentajeX ) + ", 100%, 80%)";
        circle.style.backgroundColor = color;

        circle.style.left = left + 'px';
        circle.style.top = top + 'px';
      });
}, [])

    return (
        <section id="servicios" ref={ref} className={serviciosStyles.section}>
            <div className={serviciosStyles.container}>
                <Titulo color="#000">Servicios</Titulo>
                <ul>
                    {serviciosListaHTML}
                </ul>
            </div>
            <div id="ServiciosFollow" class={serviciosStyles.follow}></div>
        </section>
    )
}
export default Servicios