import * as React from 'react'
import * as casosStyles from "./casos.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"


const Casos = () => {
    const threshold = 0.3;
    const datosCompletos = {
        "agara": {
            logo: <StaticImage width={200} height={200} transformOptions={{ fit: "inside" }} src="../images/casos/Agara3.png" alt="Agara" />,
            color: "#6a5252",
            colorTexto: "#FFF",
            texto: "Inserto en el corazón de Coyoacán, Agára es el desarrollo que conjunta en armonía la elegancia y el confort de la arquitectura moderna con la naturaleza...",
            enlace: "/pdfs/TheShapeOf-Agara.pdf",
            nombre: "Agara"
        },
        "pedre": {
            logo: <StaticImage  height={100} transformOptions={{ fit: "inside" }} src="../images/casos/Pedre3.png" alt="Pedre" />,
            color: "#000",
            colorTexto: "#FFF",
            texto: "Es el desarrollo inmobiliario que redefine la forma de vivir en el sur de la ciudad. Inspirados en la arquitectura funcional, los arquitectos Miguel De la Torre y Javier Sánchez, proponen...",
            enlace: "/pdfs/TheShapeOf-Pedre.pdf",
            nombre: "Pedre"
        },
        "factoma": {
            logo: <StaticImage width={200} height={200} transformOptions={{ fit: "inside" }} src="../images/casos/Factoma.png" alt="Factoma" />,
            color: "#333",
            colorTexto: "#FFF",
            texto: "El artista vivo con más presencia urbana en CDMX y el más activo en la última década, Jorge Cejudo, se acercó a The Shape Of para desarrollar una marca de ecommerce...",
            enlace: "/pdfs/TheShapeOf-Factoma.pdf",
            nombre: "Factoma"
        },
        "barrio": {
            logo: <StaticImage width={100} height={200} transformOptions={{ fit: "inside" }} src="../images/casos/BarrioRoma3.png" alt="Barrio Roma" />,
            color: "#a16072",
            colorTexto: "#FFF",
            texto: "Barrio Roma - Un proyecto de departamentos que contempla la rehabilitación de un inmueble con arquitectura porfiriana catalogado por el INBAL en la Roma...",
            enlace: "/pdfs/TheShapeOf-BarrioRoma.pdf",
            nombre: "BarrioRoma"
        },
        "aida": {
            logo: <StaticImage width={200} height={200} transformOptions={{ fit: "inside" }} src="../images/casos/Aida3.png" alt="Aida" />,
            color: "#1f4353",
            colorTexto: "#FFF",
            texto: "AIDA es un restaurante de cocina mexicana en Miami que fusiona los sabores tradicionales de nuestra gastronomía con creatividad y los mejores productos del mar. Inspirado en...",
            enlace: "/pdfs/TheShapeOf-Aida.pdf",
            nombre: "Aida"
        },
        "selected": "agara"
    };

    const isBrowser = () => typeof window !== "undefined";
    const [ref, inView] = useInView({ threshold })
    const [mensaje, setmensaje] = React.useState("");
    const [estado, setEstado] = React.useState(false);
    const [datos, setDatos] = React.useState({
    });
    const [hoverState, setHoverState ] = React.useState([0,0,0,0,0]);

    let formData = {
        "fields": [
        ],
        "context": {
            "pageUri": (isBrowser() ? window.location.href : ""),
            "pageName": (isBrowser() ? document.title : "")
        }
    }
    function handleChange(event) {
        formData["fields"] = [
            {
                "name": "email",
                "value": event.target.value
            },
            {
                "name": "origen",
                "value": datosCompletos["selected"]
            }
        ];
    }

    function test() {
        console.log(datosCompletos);
        isBrowser && window.open(window.location.href + datosCompletos[datosCompletos["selected"]]["enlace"], '_blank');
    }

    function handleSubmit(event) {
        event.preventDefault();
        var windowReference = 0;
        isBrowser &&  (windowReference = window.open());
        if (formData["fields"].length < 2) {
            setmensaje("Se requieren todos los campos");
            return;
        }

        var url = "https://api.hsforms.com/submissions/v3/integration/submit/22082473/8a344cf8-763c-4e4f-8004-4fd84759842d";
        fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(formData) // body data type must match "Content-Type" header
        }).then(response => {
            if (response.ok) {
                isBrowser && ( windowReference.location = datosCompletos[datosCompletos["selected"]]["enlace"] );
                //isBrowser && console.log( [window.location.href , datosCompletos[datosCompletos["selected"]]["enlace"]] );
                changeState();
            } else {
                setmensaje("Verifica tu correo.");
            }
        })
            .catch(function (error) {
                setmensaje("Hubo problemas con la red, intenta nuevamente en un minuto.")
            });

    }

    function changeState(indice = "agara") {
       // console.log(datosCompletos[indice]);
        if (datosCompletos[indice]) {
            setDatos(datosCompletos[indice]);
            datosCompletos["selected"] = indice
            setmensaje("");
            document.querySelector("#emailCasos").value="";
        }
        setEstado(!estado);
    }

    function siblingsHover(event){
        let target = event.target;
        while( target.dataset.name == undefined )
            target = target.parentElement;
        let seccion = document.getElementById("casos");
        let hoverStateNuevo = []
        if( event.type === "mouseenter"){
            for(let i = 0 ; i < seccion.childElementCount ; i++){
                if( seccion.children[i].dataset.name != target.dataset.name)
                    hoverStateNuevo.push(2);
                else
                    hoverStateNuevo.push(1);
            }
        }else if( event.type === "mouseleave"){
            for(let i = 0 ; i < seccion.childElementCount ; i++){
                hoverStateNuevo.push(0);
            }
        }
        setHoverState(hoverStateNuevo);
    }



    return (
        <><section id="casos"
            ref={ref} className={casosStyles.section}>
            <div data-name="Agara" className={[(hoverState[0]==1 ? casosStyles.width28 : ""),(hoverState[0]==2 ? casosStyles.width18 : ""), casosStyles.caso].join(" ")} onMouseEnter={siblingsHover} onMouseLeave={siblingsHover} onClick={() => changeState("agara")}  >
                <StaticImage className={casosStyles.background} layout="fullWidth" src="../images/casos/agara.jpg" alt="Agara" />
                <div><h3>Agára</h3>Leer Más</div>
                <div className={casosStyles.logo}><StaticImage width={100} height={100} transformOptions={{ fit: "inside" }} src="../images/casos/Agara3.png" alt="Agara" /><svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M42 25.05V39Q42 40.2 41.1 41.1Q40.2 42 39 42H9Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6H22.95V9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39H39Q39 39 39 39Q39 39 39 39V25.05ZM19.1 31.05 17 28.9 36.9 9H25.95V6H42V22.05H39V11.15Z" /></svg></div>
            </div>
            <div data-name="Pedre" className={[(hoverState[1]==1 ? casosStyles.width28 : ""),(hoverState[1]==2 ? casosStyles.width18 : ""), casosStyles.caso].join(" ")} onClick={() => changeState("pedre")} onMouseEnter={siblingsHover} onMouseLeave={siblingsHover}>
                <StaticImage className={casosStyles.background} layout="fullWidth" src="../images/casos/pedre.jpg" alt="Pedre" />
                <div className={casosStyles.z2}><h3>Pedre</h3>Leer Más</div>
                <div className={casosStyles.logo}><StaticImage height={100} transformOptions={{ fit: "inside" }} src="../images/casos/Pedre3.png" alt="Pedre Logo" /><svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M42 25.05V39Q42 40.2 41.1 41.1Q40.2 42 39 42H9Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6H22.95V9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39H39Q39 39 39 39Q39 39 39 39V25.05ZM19.1 31.05 17 28.9 36.9 9H25.95V6H42V22.05H39V11.15Z" /></svg></div>
            </div>
            <div data-name="Factoma" className={[(hoverState[2]==1 ? casosStyles.width28 : ""),(hoverState[2]==2 ? casosStyles.width18 : ""), casosStyles.caso].join(" ")} onClick={() => changeState("factoma")} onMouseEnter={siblingsHover} onMouseLeave={siblingsHover}>
                <StaticImage className={casosStyles.background} layout="fullWidth" src="../images/casos/factoma.jpg" alt="Factoma" />
                <div className={casosStyles.z2}><h3>Dikat</h3>Leer Más</div>
                <div className={casosStyles.logo}><StaticImage width={100} height={100} transformOptions={{ fit: "inside" }} src="../images/casos/Factoma.png" alt="Factoma Logo" /><svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M42 25.05V39Q42 40.2 41.1 41.1Q40.2 42 39 42H9Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6H22.95V9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39H39Q39 39 39 39Q39 39 39 39V25.05ZM19.1 31.05 17 28.9 36.9 9H25.95V6H42V22.05H39V11.15Z" /></svg></div>
            </div>
            <div data-name="Barrio" className={[(hoverState[3]==1 ? casosStyles.width28 : ""),(hoverState[3]==2 ? casosStyles.width18 : ""), casosStyles.caso].join(" ")} onClick={() => changeState("barrio")} onMouseEnter={siblingsHover} onMouseLeave={siblingsHover}>
                <StaticImage className={casosStyles.background} layout="fullWidth" src="../images/casos/barrio.jpg" alt="Barrio Roma" />
                <div className={casosStyles.z2}><h3>Barrio Roma</h3>Leer Más</div>
                <div className={casosStyles.logo}><StaticImage width={100} height={100} transformOptions={{ fit: "inside" }} src="../images/casos/BarrioRoma3.png" alt="Barrio Roma Logo" /><svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M42 25.05V39Q42 40.2 41.1 41.1Q40.2 42 39 42H9Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6H22.95V9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39H39Q39 39 39 39Q39 39 39 39V25.05ZM19.1 31.05 17 28.9 36.9 9H25.95V6H42V22.05H39V11.15Z" /></svg></div>
            </div>
            <div data-name="Aida" className={[(hoverState[4]==1 ? casosStyles.width28 : ""),(hoverState[4]==2 ? casosStyles.width18 : ""), casosStyles.caso].join(" ")} onClick={() => changeState("aida")} onMouseEnter={siblingsHover} onMouseLeave={siblingsHover}>
                <StaticImage className={casosStyles.background} layout="fullWidth" src="../images/casos/aida.jpg" alt="Aida" />
                <div className={casosStyles.z2}><h3>Aida</h3>Leer Más</div>
                <div className={casosStyles.logo}><StaticImage width={100} height={100} transformOptions={{ fit: "inside" }} src="../images/casos/Aida3.png" alt="Aida Logo" /><svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M42 25.05V39Q42 40.2 41.1 41.1Q40.2 42 39 42H9Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6H22.95V9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39H39Q39 39 39 39Q39 39 39 39V25.05ZM19.1 31.05 17 28.9 36.9 9H25.95V6H42V22.05H39V11.15Z" /></svg></div>
            </div>
        </section>
            <div className={[(estado ? casosStyles.popupActive : ""), casosStyles.popup].join(" ")} style={{ color: datos.colorTexto, backgroundColor: datos.color  }}>
                <div className={casosStyles.popupBackground} onClick={changeState}>
                    x
                </div>
                {datos.logo}
                <div >

                    <p className={casosStyles.popupTexto}>{datos.texto}</p>
                </div>
                <div>
                    <form method="post" action="https://api.hsforms.com" onSubmit={handleSubmit}>
                        <div className={casosStyles.formCampo}>
                            <label for="email">
                                Correo
                            </label>
                            <input type="email" name="email" id="emailCasos" onChange={handleChange} style={{ borderBottomColor: datos.colorTexto, color: datos.colorTexto }} />
                        </div>
                        <button className={casosStyles.formSubmit} type="submit" style={{ backgroundColor: datos.colorTexto, color: datos.color }}>Descargar</button>
                    </form>
                    <p>{mensaje}</p>
                </div>
                <h4>
                    The Shape Of <br /> Marketing & Business Partner
                </h4>
            </div></>
    )
}

export default Casos;