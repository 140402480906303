import * as React from 'react'
import * as nosotrosStyles from "./nosotros.module.scss"
import Titulo from './smalls/titulo'
import { useInView } from "react-intersection-observer"

function Nosotros() {

    const threshold = 0.1;
    const [ref, inView] = useInView({ threshold })

    return (
        <section id="nosotros" ref={ref} className={nosotrosStyles.section}>
            <div className={nosotrosStyles.container}>
                <Titulo>Nosotros</Titulo>
                <div>
                    <p className={nosotrosStyles.pTitulo} style={{
                opacity: inView ? 1 : 0,
                transform: `translateX(${inView ? 0 : 20}px)`,
              }}>Cualquier publicista sabe que la pregunta no es ¿qué cambiará en 5 años? sino ¿qué no cambiará en 5 años?</p>
                    <p className={nosotrosStyles.p} style={{
                opacity: inView ? 1 : 0,
                transform: `translateX(${inView ? 0 : 20}px)`,
              }}>Entendemos que las agencias no son las mismas, por ello trabajamos de forma colaborativa con nuestros clientes, involucrándolos con nuestros procesos estratégicos y creativos. La época de hacer “todo digital” ya pasó, es por eso que diseñamos soluciones omnicanal de comunicación y de negocios que se adaptan a los presupuestos reales de nuestros clientes. Sabemos que si tu negocio crece, todos crecemos.</p>
                    <span  style={{
                opacity: inView ? 1 : 0,
                transform: `translateX(${inView ? 0 : 20}px)`,
              }}>#TSOBUSINESSPARTNERS</span>
                </div>
            </div>
        </section>
    )
}
export default Nosotros