// extracted by mini-css-extract-plugin
export var background = "banner-module--background--ewxUo";
export var backgroundDesktop = "banner-module--backgroundDesktop--S3pmb";
export var backgroundMobile = "banner-module--backgroundMobile--56ELx";
export var banner = "banner-module--banner--QHcv2";
export var btn = "banner-module--btn--gAoLh";
export var btnClose = "banner-module--btnClose--FbpmE";
export var btnclose = "banner-module--btnclose--119qf";
export var popUp = "banner-module--popUp--Hz8a1";
export var popUpActive = "banner-module--popUpActive--dcFIZ";
export var videoBackground = "banner-module--videoBackground--tSuLA";
export var videoCompleto = "banner-module--videoCompleto--AReov";